import Home from "./Pages/Home";
import './index.css'
function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
