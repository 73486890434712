import { useEffect, useState } from 'react';
import styles from './index.module.css'
import PlantImage from '../../assets/imgs/plant.png'
const Home = () => {
    const route = ['HOME', 'NOTE', 'LIFE', 'ABOUT']
    const [beginPoint, setBeginPoint] = useState([0, 0])
    const [endPoint, setEndPoint] = useState([0, 0])

    const getSize = () => {
        const contentDom = document.getElementById('content')
        const height = contentDom?.offsetHeight || 0
        const width = contentDom?.offsetWidth || 0
        setBeginPoint([0, height * 0.1])
        setEndPoint([width, height * 0.9])
    }
    useEffect(() => {
        getSize();
        window.onresize = () => { getSize() }
    }, [])

    const handleClickBeian = () =>{
        window.open("http://beian.miit.gov.cn/");
    }

    return (
        <div className={styles.home}>
            <div className={styles.header}>
                <div className={styles.routers}>
                    {route.map(item => {
                        return <div className={styles['route-item']}>{item}</div>
                    })}
                </div>
            </div>
            <div className={styles.content} id="content">
                <svg width="100%" height="90%">
                    <line x1={beginPoint[0]} y1={beginPoint[1]} x2={endPoint[0]} y2={endPoint[1]} stroke="black" strokeWidth="1px" />
                </svg>
                <h1 className={styles.title}>星河记</h1>
                <img alt='' src={PlantImage} className={styles.plant} />
                <div className={styles['sub-title']}>
                    <div>星垂平野阔，</div>
                    <div>月涌大江流。</div>
                </div>
            </div>
            <div className={styles.footer}>
                <span onClick={handleClickBeian}>陕ICP备2023018949号</span>
            </div>
        </div>
    );
};


export default Home;
